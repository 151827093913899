











































































import { Component, Mixins, Prop } from 'vue-property-decorator';
import AppHeader from '@/ui/components/organisms/AppHeader.vue';
import CampaignsList from '@/ui/components/organisms/campaigns/CampaignsList.vue';
import { NavigationMixin } from '@/ui/mixins/NavigationMixin';
import ButtonWithText from '@/ui/components/atoms/buttons/ButtonWithText.vue';
import ButtonWithTextAndIcon from '@/ui/components/atoms/buttons/ButtonWithTextAndIcon.vue';
import type { Campaign } from '@/core/campaign/models/Campaign';
import LabelChip from '@/ui/components/atoms/groupingItems/LabelChip.vue';
import type { LanguageObject } from '@/core/campaign/models/LanguageObject';
import { getCampaignByIdUseCase } from '@/core/campaign/usecases/getCampaignByIdUseCase';
import { AdminStoreMutations } from '@/ui/stores/AdminStore';
import { ROUTE_EDIT_CAMPAIGN } from '../router/campaign.routes';
import { USER_ROLE } from '@/core/user/enums/UserRole';
import { CampaignStoreGetters } from '@/ui/stores/CampaignStore';
import { Languages } from '@/core/admin/constants/Languages.const';
import { CAMPAIGN_DETAILS_TABS } from '@/ui/constant/tabs.constant';
import { NavigationTab } from '@/ui/models/NavigationTab';
import { USER_PERMISSION } from '@/core/user/enums/UserPermission';
import { copyTextToClipboard } from '@/ui/util/clipboard';
import { Loader } from '@/ui/util/decorators/loader';
import i18n from '@/i18n';
import { ROUTE_NAMES } from '../constant/routes.constants';
import { PermanentStoreMutations } from '@/ui/stores/PermanentStore';
import { Dictionary } from 'tsyringe/dist/typings/types';

@Component({
  name: 'CampaignDetailView',
  components: {
    LabelChip,
    ButtonWithTextAndIcon,
    ButtonWithText,
    AppHeader,
    CampaignsList,
  },
})
export default class CampaignDetailView extends Mixins(NavigationMixin) {
  @Prop({ required: true }) campaignId!: string;
  private dateOptions: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };
  private locale: keyof LanguageObject = i18n.i18next.language;
  public tabItems = CAMPAIGN_DETAILS_TABS;

  async mounted() {
    await this.getCampaign();
  }

  get currentUser() {
    return this.$store.state.userStore.currentUser;
  }

  get canEditCampaign() {
    return this.currentUser?.role !== USER_ROLE.USER;
  }

  get currentTab() {
    return this.$route.name === ROUTE_NAMES.CAMPAIGN_DETAIL_MEDIA ? 0 : 1;
  }

  get allowedTabItems() {
    const tabItemArray = Object.values(this.tabItems);
    const user = this.currentUser;

    return tabItemArray.filter((tabItem: NavigationTab) => {
      if (user.role === USER_ROLE.SUPER_ADMIN || user.role === USER_ROLE.ADMIN) {
        return true;
      }

      const requiredPermissions = tabItem.requiredPermissions;
      if (!requiredPermissions) {
        return true;
      }

      const hasAllRequiredPermissions = requiredPermissions.every((p: USER_PERMISSION) => user.permissions.includes(p));
      return !!hasAllRequiredPermissions;
    });
  }

  get startDate() {
    return this.selectedCampaign
      ? new Date(this.selectedCampaign.startDate).toLocaleString(Languages.DE, this.dateOptions)
      : '';
  }

  get endDate() {
    return this.selectedCampaign
      ? new Date(this.selectedCampaign.endDate).toLocaleString(Languages.DE, this.dateOptions)
      : '';
  }

  get selectedCampaign(): Campaign | undefined {
    return this.$store.getters[CampaignStoreGetters.GET_SELECTED_CAMPAIGN];
  }

  get description(): string | undefined {
    return this.selectedCampaign?.description;
  }

  async copyToClipboard(campaignId: string) {
    await copyTextToClipboard(campaignId);
  }

  @Loader
  async getCampaign() {
    await getCampaignByIdUseCase(this.currentUser, this.campaignId);
  }

  editCampaign(): void {
    this.$store.commit(AdminStoreMutations.SET_CAMPAIGN_TO_EDIT, this.selectedCampaign);
    this.updateLastSavedParentRoute(this.$route.name, this.$route.params);
    this.goTo(ROUTE_EDIT_CAMPAIGN);
  }

  handleGoBack() {
    this.$router.push({ name: this.$store.state.permanentStore.lastSavedParentRouteForCampaignDetails });
  }

  updateLastSavedParentRoute(routeName: string | null | undefined, routeParams: Dictionary<string>) {
    this.$store.commit(PermanentStoreMutations.SET_LAST_SAVED_PARENT_ROUTE_FOR_EDIT_CAMPAIGN, routeName);
    this.$store.commit(PermanentStoreMutations.SET_LAST_SAVED_PARENT_ROUTE_PARAMS_FOR_EDIT_CAMPAIGN, routeParams);
  }
}

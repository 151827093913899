import { ROUTE_NAMES } from '@/ui/constant/routes.constants';
import { USER_PERMISSION } from '@/core/user/enums/UserPermission';
import type { User } from '@/core/admin/models/User';
import { USER_ROLE } from '@/core/user/enums/UserRole';

export type ADMIN_TAB_KEY = 'USERS' | 'CAMPAIGN' | 'LABELS' | 'VERSION';
export interface Tab {
  id: number;
  name: string;
  to: string;
  roles?: USER_ROLE[];
}
export interface AdminTab extends Tab {
  key: ADMIN_TAB_KEY;
}

const ADMIN_TABS: AdminTab[] = [
  {
    id: 0,
    key: 'USERS',
    name: 'Admin.general.tab-user',
    to: ROUTE_NAMES.ADMIN_USERS,
  },
  {
    id: 1,
    key: 'CAMPAIGN',
    name: 'Admin.general.tab-campaign',
    to: ROUTE_NAMES.ADMIN_CAMPAIGNS,
  },
  {
    id: 2,
    key: 'LABELS',
    name: 'Admin.general.tab-labels',
    to: ROUTE_NAMES.ADMIN_LABELS,
  },
  {
    id: 3,
    key: 'VERSION',
    name: 'Admin.general.tab-version',
    to: ROUTE_NAMES.ADMIN_VERSION,
    roles: [USER_ROLE.SUPER_ADMIN],
  },
];

export function getAdminTabs(currentUser: User) {
  return ADMIN_TABS.filter((tab) => (tab.roles ? tab.roles.includes(currentUser.role) : true));
}

export function tabFromRoute(route: string) {
  return ADMIN_TABS.find((tab) => tab.to === route) ?? null;
}

export const CAMPAIGN_DETAILS_TABS = {
  MEDIA: {
    name: 'campaign.campaign-detail.tab-item-media',
    id: 0,
    to: ROUTE_NAMES.CAMPAIGN_DETAIL_MEDIA,
  },
  STATISTICS: {
    name: 'campaign.campaign-detail.tab-item-statistics',
    id: 1,
    to: ROUTE_NAMES.CAMPAIGN_DETAIL_STATISTICS,
    requiredPermissions: [USER_PERMISSION.STATISTICS],
  },
};

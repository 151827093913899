import { container } from 'tsyringe';
import { CampaignService } from '@/core/campaign/services/CampaignService';
import type { Campaign } from '@/core/campaign/models/Campaign';
import type { CurrentUser } from '@/core/user/models/CurrentUser';
import store from '@/ui/store';
import { CampaignStoreActions } from '@/ui/stores/CampaignStore';

export const getCampaignByIdUseCase = async (currentUser: CurrentUser, campaignId: string): Promise<Campaign> => {
  const campaign: Campaign = await container
    .resolve<CampaignService>('CampaignService')
    .getCampaignById(currentUser, campaignId);
  await store.dispatch(CampaignStoreActions.TRIGGER_SET_SELECTED_CAMPAIGN, campaign);
  return campaign;
};
